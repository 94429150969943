import React, { useEffect, useContext } from 'react';
import { store } from '../../store';
import { fetchReservation, fetchCheckedInReservation, createCheckout } from '../../actions';

import ReservationDetails from '../../components/ReservationDetails';
import AppActions from '../../components/AppActions';

import './Checkin.css';

const Checkin = () => {
  const { state, dispatch } = useContext(store);

  const {
    location,
    user,
    reservation,
    message,
    action,
    title,
  } = state;

  (async () => {
    if (user.email && location.name && !reservation.state) {
      const resAction = await fetchReservation(user.email, location.timekit_id);
      dispatch(resAction);
    }
  })();

  return (
    <div className="Checkin">
      <div className="App-content-box">
        <ReservationDetails
          reservation={reservation}
          message={message}
          title={title}
        />
      </div>
      <AppActions action={action} />
    </div>
  );
}

const Checkout = ({ locationId }) => {
  const { state, dispatch } = useContext(store);

  const {
    user,
    location,
    reservation,
    message,
    action,
    title,
  } = state;

  useEffect(() => {
    (async () => {
      if (user.email && location.name) {
        const checkedInAction = await fetchCheckedInReservation(user.email, location.timekit_id);
        dispatch(checkedInAction);
      }

      if (reservation.state === 'checked-in') {
        const resAction = await createCheckout(reservation);
        dispatch(resAction);
      }
    })();
  }, [dispatch, location, reservation.state, user.email])

  return (
    <div className="Checkout">
      <div className="App-content-box">
        <ReservationDetails
          reservation={reservation}
          message={message}
          title={title}
        />
      </div>
      <AppActions action={action} />
    </div>
  );
}

export default function Base({ task, locationId }) {
  return task === 'checkout'
    ? <Checkout locationId={locationId} />
    : <Checkin />;
}
