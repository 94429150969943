import React, { createContext, useReducer } from 'react';

const initialState = {
  token: null,
  location: {},
  user: {},
  reservation: {},
  message: '',
  action: '',
  title: '',
  loader: true,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state = {}, action = {}) => {
    if (!action) {
      return state;
    }

    switch(action.type) {
      case 'LOCATION.UPDATE':
        return {
          ...state,
          location: action.payload,
        };
      case 'USER.UPDATE':
        return {
          ...state,
          user: action.payload.user,
          token: action.payload.token,
        };
      case 'RESERVATION.UPDATE':
        return {
          ...state,
          reservation: action.payload,
        };
      case 'MESSAGE.UPDATE':
        return {
          ...state,
          message: action.payload.message,
          action: action.payload.action,
          title: action.payload.title,
        };
      case 'LOADER.UPDATE':
        return {
          ...state,
          loader: action.payload,
        };
      default:
        return state;
    };
  }, initialState);

  return (
    <Provider value={{ state, dispatch }}>
      {children}
    </Provider>
  );
};

export { store, StateProvider };
