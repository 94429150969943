import React from 'react';

import ReservationDetails from '../../components/ReservationDetails';
import AppActions from '../../components/AppActions';

import './Error.css';

const ERR_TITLE = `Rescan QR Code`;
const ERR_MESSAGE = `Hmm, you're not supposed to be here. Please rescan the QR code to get back on track!`;

export default function Error() {
  return (
    <div className="Checkin">
      <div className="App-content-box">
        <ReservationDetails
          reservation={{}}
          title={ERR_TITLE}
          message={ERR_MESSAGE}
        />
      </div>
      <AppActions />
    </div>
  );
}
