import React, { useContext } from 'react';
import { store } from '../../store';

import './AppActions.css';

import {
  redirectToSSO,
  redirectToApp,
  createCheckin,
} from '../../actions';

const delegateClick = async (action, state) => {
  if (action.value === 'login') {
    await redirectToSSO();
  } else if (action.value === 'reserve') {
    return redirectToApp();
  } else if (action.value === 'check-in') {
    return createCheckin(state.reservation);
  }

  return;
};

export default function AppActions({ action }) {
  const { state, dispatch } = useContext(store);

  const handleClick = async (e) => {
    e.preventDefault();

    const buttonAction = await delegateClick(action, state);
    dispatch(buttonAction);
  }

  return (
    <div className="App-actions">
      {action && !action.hide &&
        (
          <button className="button" onClick={handleClick}>
            {action.label}
          </button>
        )
      }
      <div className="links">
        <div className="link">
          <div><strong>Need help on-site?</strong></div>
          <div>
            An on-site staff member is happy to assist.
          </div>
        </div>
        <div className="link">
          <div><strong>App Assistance?</strong></div>
          <div>
            Email nyu@kettlespace.com or text/call 914-600-3390.
          </div>
        </div>
      </div>
    </div>
  );
}
