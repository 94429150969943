import React, { useContext, useEffect } from 'react';
import { createBrowserHistory } from 'history';

import { store } from './store';

import App from './screens/App';
import { fetchUser, updateMessage, updateLoader } from './actions';
import { getUrlParams } from './utils';

const history = createBrowserHistory();

const route = () => {
  const routes = ['checkin', 'checkout'];
  const [, task, locationId] = window.location.pathname.split('/');

  if (!locationId) {
    return <App />;
  }

  if (routes.includes(task)) {
    return <App task={task} locationId={locationId} />;
  }

  return <App />;
}

export default function AppRouter() {
  const { state, dispatch } = useContext(store);
  const { user, location, token, reservation } = state;

  useEffect(() => {
    const dispatchUser = async () => {
      const userAction = await fetchUser(token);
      dispatch(userAction);
    }

    dispatch(updateLoader(true));

    const redirect = getUrlParams('redirect');
    if (redirect) {
      history.push(redirect);
    }

    if (!user.email) {
      dispatchUser();
    }

    const messageAction = updateMessage(user.email, reservation.state, {
      location: location.name,
      seat: reservation.title,
    });
    dispatch(messageAction);

    dispatch(updateLoader(false));
  }, [dispatch, reservation, user.email, token, location.name]);

  return route();
}
