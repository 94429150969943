const titles = {
  login: `Please Login`,
  not_found: `Reservation Not Found`,
  check_in: `Reservation Found`,
  view_seat: `Checked In`,
  checked_out: `Checked Out`,
};

const messages = {
  login: `Please sign in using your NYU NetID to begin the check-in process. Once checked in, you will be assigned a seat.`,
  not_found: `You have no upcoming reservation at this space. This may be because you are arriving 30 minutes too early or too late. Please make a new reservation in the app.`,
  // too_early: `You’re a bit early! Your reservation doesn’t start for another few minutes. Please adjust your reservation start time.`,
  // too_late: `Unfortunately, you are too late to check in to your existing reservation. Please make a new reservation and try again.`,
  check_in: `Does this look right to you? Tap “Check In” to receive your seat number.`,
  view_seat: `You have successfully checked in. You can find your seat number above. Please seat yourself.`,
  checked_out: `You have successfully checked out. Have a great day!`,
};

const actions = {
  login: {
    label: 'Connect with NYU NetID',
    value: 'login',
  },
  check_in: {
    label: 'Check In',
    value: 'check-in',
  },
  check_out: {
    label: 'Check Out',
    value: 'check-out',
    hide: true,
  },
  checked_out: {
    label: 'Checked Out',
    value: 'checked-out',
    hide: true,
  },
  make_reservation: {
    label: 'Reserve a Seat',
    value: 'reserve',
  },
};

const urls = {
  api: process.env.REACT_APP_CORE_API_BASE,
  bookings: process.env.REACT_APP_RESERVATIONS_API_BASE,
  deeplink: process.env.REACT_APP_DEEPLINK,
  env: process.env.REACT_APP_ENV,
};

export { titles, messages, actions, urls };
