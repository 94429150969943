import React from 'react';
import moment from 'moment-timezone';
import './Header.css';

const TODAY = moment.utc()
  .tz('America/New_York')
  .format('dddd, MMMM DD, YYYY');

export default function Header({ text = '' }) {
  return (
    <header className="App-header">
      <h1>NYU</h1>
      <p>{TODAY}</p>
      <h2>{text}</h2>
    </header>
  );
}
