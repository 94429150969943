import React, { useContext } from 'react';
import moment from 'moment-timezone';

import './ReservationDetails.css';
import nyuLogo from '../../images/nyu-logo.png';
import { store } from '../../store';

const parseJson = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  };
}

const displaySeat = (reservation, location) => {
  const isQuickStop = location.types?.includes('quick-stop');

  return isQuickStop
    ? 'Take Any Available Seat'
    : reservation.title;
}

const DetailBox = ({ reservation }) => {
  const { state } = useContext(store);
  const { location } = state;
  const notes = parseJson(reservation.notes);

  return (
      <div className="detail-box">
        {
          reservation.state === 'checked-in'
            && displaySeat(reservation, location)
        }
        {
          reservation.state === 'checked-out'
            && `Checked Out At
            ${moment(notes.checked_out_at)
              .tz('America/New_York')
              .format('h:mm A')
            }`
        }
      </div>
  );
};

const Reservation = ({ reservation }) => (
  <>
    {
      ['checked-in', 'checked-out'].includes(reservation.state)
        && (<DetailBox reservation={reservation} />)
    }
    <div className="label">
      NetId
    </div>
    <div className="value">
      {reservation.email}
    </div>
    <div className="label">
      Date
    </div>
    <div className="value">
      {
        moment.utc(reservation.start_time)
        .tz('America/New_York')
        .format('dddd, MMMM DD')
      }
    </div>
    <div className="label">
      Start Time
    </div>
    <div className="value">
      {
        moment.utc(reservation.start_time)
        .tz('America/New_York')
        .format('h:mm A')
      }
    </div>
    <div className="label">
      End Time
    </div>
    <div className="value">
      {
        moment.utc(reservation.end_time)
        .tz('America/New_York')
        .format('h:mm A')
      }
    </div>
  </>
);

export default function ReservationDetails({ reservation, title, message }) {
  return (
    <div className="App-reservation-details">
      <div className="title">
        {title}
      </div>
      {
        reservation.state
          ? <Reservation reservation={reservation} />
          : <div className="logo">
              <img
                src={nyuLogo}
                alt="NYU logo"
              />
            </div>
      }
      <hr />
      <div className="hint">
        {message}
      </div>
    </div>
  );
}
