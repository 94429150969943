import React, { useEffect, useContext } from 'react';
import { store } from '../../store';
import { fetchLocation } from '../../actions';

import Login from '../Login';
import Checkin from '../Checkin';
import Error from '../Error';
import Header from '../../components/Header';

import './App.css';

const Layout = ({ children }) => {
  const { state } = useContext(store);
  const { location, loader } = state;


  return (
    <div className={`App-${loader ? 'loading' : 'loaded'}`}>
      <Header text={location.name || 'Welcome'} />
        { children }
    </div>
  );
}

export default function App({ locationId, task }) {
  const { state, dispatch } = useContext(store);

  const {
    location,
    user,
    token,
  } = state;

  const isLoggedIn = user.email;
  const headerLength = location.name ? location.name.length : 8;

  useEffect(() => {
    (async() => {
      if (locationId && !location.name && user.email) {
        const locationAction = await fetchLocation(locationId, user.companyObj.id, token);
        dispatch(locationAction);
      }
    })();
  }, [dispatch, location.name, locationId, token, user]);

  if (!task) {
    return (
      <Layout>
        <section className="App-content" style={{
          marginTop: '-240px',
        }}>
          <Error task={task} locationId={locationId} />
        </section>
      </Layout>
    )
  }

  if (isLoggedIn) {
    return (
      <Layout>
        <section className="App-content" style={{
          marginTop: `${-300 + 24.6*(headerLength/20)}px`,
        }}>
          <Checkin task={task} locationId={locationId} />
        </section>
      </Layout>
    )
  }

  return (
    <Layout>
      <section className="App-content" style={{
        marginTop: '-240px',
      }}>
        <Login task={task} locationId={locationId} />
      </section>
    </Layout>
  );
}
