import React, { useContext } from 'react';
import { store } from '../../store';
import ReservationDetails from '../../components/ReservationDetails';
import AppActions from '../../components/AppActions';

import './Login.css';

export default function Login() {
  const { state } = useContext(store);

  const {
    message,
    action,
  } = state;

  return (
    <div className="Login">
      <div className="App-content-box">
        <ReservationDetails
          reservation={{}}
          message={message}
        />
      </div>
      <AppActions action={action} />
    </div>
  );
}
