import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';

import {
  titles,
  messages,
  actions,
  urls,
} from '../constants';

import { getUrlParams } from '../utils';

const parseJson = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  };
}

const parseSeat = (title) => {
  try {
    if (!title) {
      return '';
    }

    const formalName = title.toLowerCase();
    const hasSeat = formalName.includes('seat');

    if (hasSeat) {
      const seatNum = formalName.split('seat').pop().trim();
      return seatNum || title;
    }

    return title;
  } catch (e) {
    return title;
  }
}

export const fetchUpcomingReservation = async (email, timekit_id) => {
  const userEmail = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_TEST_NYU_USER : email;

  const { data } = await axios.get(`${urls.bookings}/reservations/closest`, {
    params: {
      email: userEmail,
      location: timekit_id,
    },
  });

  return data.data
    ? {
      type: 'RESERVATION.UPDATE',
      payload: data.data,
    } : null;
};

export const fetchCheckedInReservation = async (email, timekit_id) => {
  const userEmail = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_TEST_NYU_USER : email;

  const { data } = await axios.get(`${urls.bookings}/reservations/active`, {
    params: {
      email: userEmail,
      location: timekit_id,
    },
  });

  return data.data
    ? {
      type: 'RESERVATION.UPDATE',
      payload: data.data,
    } : null;
};

export const fetchReservation = async (email, timekit_id) => {
  try {
    const upcomingRes = await fetchUpcomingReservation(email, timekit_id);
    if (upcomingRes) {
      return upcomingRes;
    }

    // No upcoming?
    // Check if there is an existing checked-in location

    const existingRes = await fetchCheckedInReservation(email, timekit_id);
    if (existingRes) {
      return existingRes;
    }

    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchUser = async (token) => {
  try {
    let sessionToken = token;
    if (!sessionToken) {
      sessionToken = Cookies.get('jwt');
    }
    if (!sessionToken) {
      sessionToken = getUrlParams('token');
    }

    if (!sessionToken) {
      return null;
    }

    const { data } = await axios.request({
      url: `${urls.api}/users/me`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      },
    });

    const jwt = Cookies.get('jwt');
    if (!jwt) {
      Cookies.set('jwt', sessionToken, { expires: 3 });
    }

    // clear out token token params from url
    window.history.pushState({}, document.title, window.location.pathname);

    return {
      type: 'USER.UPDATE',
      payload: {
        user: data,
        token: sessionToken,
      },
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchLocation = async (locationId, companyId, token) => {
  try {
    if (!locationId || !companyId || !token) {
      return null;
    }

    const { data } = await axios.request({
      url: `${urls.api}/locations`,
      method: 'GET',
      params: {
        qr_code_id: locationId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (data) {
      return {
        type: 'LOCATION.UPDATE',
        payload: data,
      };
    }

    // otherwise, look up space directly

    const res = await axios.request({
      url: `${urls.api}/actions/spaces`,
      method: 'GET',
      params: {
        qr_code_id: locationId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!res.data) {
      return null;
    }

    if (res.data) {
      return {
        type: 'LOCATION.UPDATE',
        payload: res.data,
      };
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const updateMessage = (userEmail, resState) => {
  const action = {
    type: 'MESSAGE.UPDATE',
    payload: {
      title: titles.login,
      message: messages.login,
      action: actions.login,
    },
  };

  if (!userEmail) {
    action.payload.title = titles.login;
    action.payload.message = messages.login;
    action.payload.action = actions.login;
  } else if (!resState) {
    action.payload.title = titles.not_found;
    action.payload.message = messages.not_found;
    action.payload.action = actions.make_reservation;
  } else if (resState === 'confirmed') {
    action.payload.title = titles.check_in;
    action.payload.message = messages.check_in;
    action.payload.action = actions.check_in;
  } else if (resState === 'checked-in') {
    action.payload.title = titles.view_seat;
    action.payload.message = messages.view_seat;
    action.payload.action = actions.check_out;
  } else if (resState === 'checked-out') {
    action.payload.title = titles.checked_out;
    action.payload.message = messages.checked_out;
    action.payload.action = actions.checked_out;
  } else {
    action.payload.title = titles.login;
    action.payload.message = messages.login;
    action.payload.action = actions.login;
  }

  return action;
};

export const updateLoader = bool => ({
  type: 'LOADER.UPDATE',
  payload: bool,
});

export const redirectToSSO = async () => {
  const authorizer = `${urls.api}/auth/nyu`;
  const domain = `${window.location.origin}${window.location.pathname}`;
  const params = window.location.search ? window.location.search.slice(1) : '';

  const authorizerUrl = `${authorizer}?domain=${domain}&params=${params}`;

  const { data: { redirect } } = await axios.request({
    url: authorizerUrl,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (process.env.REACT_APP_ENV === 'local') {
    const { data: { token } } = await axios.request({
      url: `${urls.api}/auth`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email: process.env.REACT_APP_ADMIN_KS_EMAIL,
        password: process.env.REACT_APP_ADMIN_KS_PASSWORD,
      }
    });

    window.location.href = `${domain}?token=${token}&${params || ''}`;
    return null;
  } else {
    window.location.href = redirect;

    return null;
  }
}

export const redirectToApp = async () => {
  window.location.href = urls.deeplink;
}

export const createCheckin = async (res) => {
  try {
    const notes = parseJson(res.notes);

    await axios.put(`${urls.bookings}/reservation?id=${res.id}`, {
      state: 'checked-in',
      notes: JSON.stringify(Object.assign(notes, {
        seat: parseSeat(res.title),
        checked_in_at: moment.utc().tz('America/New_York').toISOString(),
      })),
    });

    const { data } = await axios.request({
      url: `${urls.bookings}/reservation?id=${res.id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return {
      type: 'RESERVATION.UPDATE',
      payload: data.data || {},
    }
  } catch(e) {
    console.error(e);
    return null;
  }
};

export const createCheckout = async (res) => {
  try {
    const notes = parseJson(res.notes);

    await axios.put(`${urls.bookings}/reservation?id=${res.id}`, {
      state: 'checked-out',
      notes: JSON.stringify(Object.assign(notes, {
        checked_out_at: moment.utc().tz('America/New_York').toISOString(),
      })),
    });

    const { data } = await axios.request({
      url: `${urls.bookings}/reservation?id=${res.id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return {
      type: 'RESERVATION.UPDATE',
      payload: data.data || {},
    }
  } catch(e) {
    console.error(e);
    return null;
  }
};
